import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const ARTICLE_ENTITY_LIST_SEARCH_FRAGMENT = `
  fragment articleEntityListSearchFragment on Article {
    id
    uid
    __typename
    schemaCode
    name
    subtitle
    images: articleImages(first: 1) {
      ...fileResourceBaseFragment
    }
    _isBookmarked(myUid: "%authUser%")
    _isFollowed(myUid: "%authUser%")
    score: _score
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
